<template>
  <div class="content">
    <ContentHeader title="宅配ボックス情報登録" />
    <LockerRegistFlow
      input_status="done"
      confirm_status="done"
      complete_status="done"
    />
    <ExplainLink />
    <article class="message is-success is-small">
      <div class="message-body">
        ボックス情報登録完了メールをご確認ください。
      </div>
    </article>
  </div>
</template>

<script>
import LockerRegistFlow from "../components/elements/LockerRegistFlow.vue"
import ExplainLink from "../components/elements/ExplainLink.vue"
import ContentHeader from "../components/ContentHeader.vue";

export default {
  name: "LockerRegistComplete",
  components: {
    LockerRegistFlow,
    ContentHeader,
    ExplainLink,
  },
}
</script>

<style scoped>
.reception-msg {
  text-align: center;
  font-weight: bold;
}
.smari-no {
  margin-bottom: 40px;
}
.email-notify {
  padding-top: 50px;
}
</style>
